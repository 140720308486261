import React, { Component } from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { map, orderBy, isEmpty } from 'lodash';
import '../components/layout.css';
import SEO from '../components/seo';
import Background from '../components/Background';
import BirthDateModal from '../components/BirthDateModal';
import CityDropdown from '../components/CityDropdown';
import StandingsTable from '../components/StandingsTable';
import StandingsAccordion from '../components/StandingsAccordion';
import ChampionshipEventDetails from '../components/ChampionshipEventDetails';
import WinnerSection from '../components/WinnerSection';
import Header from '../components/TopHeader';
import Footer from '../components/Footer';
import keyWest from '../assets/images/key-west-logo.png';
import stolimpics from '../assets/images/Stoli_Stolimpics_logo.png';
import breakpoints from '../utils/breakpoints';
import colors from '../utils/colors';
import { todayFormatted, startDate, endDate } from '../utils/votingDates';
import events from '../services/events';

const styles = {
  dropdown: {
    marginTop: '60px',
  },
  table: {
    marginTop: '40px',
    padding: '0 20px',
  },
  eventDetails: {
  },
};

class IndexPage extends Component {
  state = {
    showModal: true,
    showHeader: false,
    cities: [],
    eventData: [],
    contestants: [],
    referentialScore: 25,
    tieDisclaimer: null,
    tie: false,
    isLA: false,
    loading: false,
    showVoteButton: false,
    isPostFinale: true,
  }

  componentDidMount = () => {
    if (localStorage.getItem('modalAccepted')) this.setState({ showModal: false });
    events.fetchEvents().then((response) => {
      const cities = map(response.data, event => ({
        id: event.id, city: event.city, outsideUs: event.outside_us }
      ));
      this.setState({ cities });
      this.setState({ eventData: response.data });
    });
    window.addEventListener('scroll', this.handleScroll);

    this.setState({ showVoteButton: todayFormatted > startDate && todayFormatted < endDate });
  }

  handleScroll = () => {
    const { showHeader } = this.state;
    const threshold = window.innerWidth < breakpoints.sm.max ? 75 : 127;
    if (window.scrollY >= threshold && !showHeader) this.setState({ showHeader: true });
    else if (window.scrollY < threshold && showHeader) this.setState({ showHeader: false });
  };

  handleSelection = async (city) => {
    this.setState({ isLA: city.city === 'Los Angeles, CA', loading: true });

    await events.fetchEventsDetails(city.id).then((response) => {
      const { contestants } = response.data;
      const orderedContestants = orderBy(contestants, 'position');
      const parsedData = map(orderedContestants, contestant => ({
        id: contestant.id,
        name: contestant.bartender_name,
        place: contestant.position,
        overall: Math.round(contestant.percentage),
        blindSampling: contestant.blind_vote_score,
        textVotes: contestant.public_vote_score,
        cocktail: contestant.cocktail_vote_score,
        presentation: contestant.presentation_vote_score,
        tie: contestant.tie_member,
      }));
      this.setState({
        contestants: parsedData,
        referentialScore: response.data.referential_score,
        tieDisclaimer: response.data.tie_legal_disclaimer,
        tie: response.data.tie,
      });
    });
    this.setState({ loading: false });
  }

  onModalSubmit = () => {
    localStorage.setItem('modalAccepted', true);
    this.setState({ showModal: false });
  }

  handleVoteRedirect = () => {
    window.location = 'fan_favourite';
  };

  render = () => {
    const {
      showModal, showHeader, cities, contestants, referentialScore, tieDisclaimer, tie, eventData,
      isLA, loading, showVoteButton, isPostFinale,
    } = this.state;

    return (
      <Wrapper className={showModal && 'hide-overflow'}>
        <SEO
          title="Stoli® Vodka"
          description="Stoli® Vodka is the original premium vodka that combines modern distilling of the highest quality spirit with century old traditions to create its bold character."
        />
        <Background blur={showModal} />
        {showHeader && <Header />}

        <LogoContainer>
          <StyledStolimpics src={stolimpics} />
          <StyledKeyWest src={keyWest} />
        </LogoContainer>

        <Layout
          className={showModal && 'blur'}
          showVoteButton={showVoteButton}
        >
          {showVoteButton && (
          <ButtonWrapper>
            <ButtonContainer onClick={this.handleVoteRedirect}>
              <InsideContainer>
                <VoteHereText>
                  VOTE FOR YOUR
                  <br />
                  FAVOURITE BARTENDER
                </VoteHereText>
                <ClickHere>
                  CLICK HERE
                </ClickHere>
              </InsideContainer>
            </ButtonContainer>
          </ButtonWrapper>
          )}
          <CheckStandings>
            Competition Scoring:&nbsp;
            <Results>The Results!</Results>
          </CheckStandings>
          {tie && tieDisclaimer && (
            <TieDisclaimer>
              {tieDisclaimer}
            </TieDisclaimer>
          )}
          <CityDropdown
            cities={cities.filter(city => !city.outsideUs)}
            onSelection={this.handleSelection}
          />
          {isLA && !loading && (
            <Champion>
              2019 L.A. Champion: Tamara Malais
            </Champion>
          )}
          {!isEmpty(contestants) && (
            <MediaQuery maxWidth={breakpoints.sm.max}>
              <StandingsAccordion
                standings={contestants}
                style={styles.table}
                referentialScore={referentialScore}
                isLA={isLA}
                loading={loading}
              />
            </MediaQuery>
          )}
          {!isEmpty(contestants) && (
            <MediaQuery minWidth={breakpoints.sm.max}>
              <StandingsTable
                standings={contestants}
                style={styles.table}
                referentialScore={referentialScore}
                isLA={isLA}
                loading={loading}
              />
            </MediaQuery>
          )}
          {isLA && !loading && (
            <SmallDisclaimer>
              *Declined to compete in finale.
            </SmallDisclaimer>
          )}
          {isPostFinale && <WinnerSection />}
          <EventWrapper>
            <ChampionshipEventDetails
              style={styles.eventDetails}
              events={eventData}
              isPostFinale={isPostFinale}
            />
          </EventWrapper>
        </Layout>
        <Footer />
        {showModal && <BirthDateModal onSubmit={this.onModalSubmit} />}
      </Wrapper>
    );
  }
}

const Layout = styled.div`
  background-color: white;
  margin: 0px 40px 0px 40px;
  padding-top: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;

  @media (max-width: ${breakpoints.md.max}px) {
    margin: 19.556714472vw 2.6075619296vw 2.6075619296vw;
  }

  @media (max-width: ${breakpoints.sm.max}px) {
    margin: ${props => props.showVoteButton ? '300px' : '17.6666666667vw'} 20px 0px 20px;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    margin: ${props => props.showVoteButton ? '300px' : '26.6666666667vw'} 20px 0px 20px;
    box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.24);
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

`;


const CheckStandings = styled.div`
  color: ${colors.championshipBackground};
  margin-top: 20px;
  font-family: 'Tahoma-Bold';
  font-size: 34px;
  letter-spacing: 3px;

  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 26px;
    margin-top: 15px;
  }

  @media (max-width: ${breakpoints.sm.max}px) {
    margin-top: 16px;
    text-align: center;
    font-size: 19.2px;
    letter-spacing: 2px;
  }
`;

const Results = styled(CheckStandings)`
  display: inline-block;

  @media (max-width: ${breakpoints.sm.max}px) {
    margin-top: 3px;
    display: block;
  }
`;

const StyledStolimpics = styled.img`
  height: 6.6145833333vw;
  background-color: white;
  margin: 2.0833333333vw 1.6666666667vw;
  
  @media (max-width: ${breakpoints.sm.max}px) {
    height: 17.0666666667vw;
    margin: 3.7333333333vw 9.3333333333vw;
  }
`;

const StyledKeyWest = styled.img`
  height: 8.9583333333vw;
  margin: 2.0833333333vw 1.6666666667vw;
  background-color: white;

  @media (max-width: ${breakpoints.sm.max}px) {
    height: 23.467vw;
    margin: 3.7333333333vw 9.3333333333vw 3.7333333333vw 0;
  }
`;

const LogoContainer = styled.div`
  margin-top: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 8.4vw;
  z-index: 1;

  @media (max-width: ${breakpoints.lg.max}px) {
    display: none;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    display: flex;
    max-width: 70.1333333333vw;
    margin-top: 0;
    top: 28.4vw;
    z-index: -1;
  }
`;

const EventWrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  margin-top: 32px;

  @media (max-width: ${breakpoints.lg.max}px) {
    margin-top: 22px;
    padding: 20px 20px;
  }

  @media (max-width: ${breakpoints.md.max}px) {
    margin-top: 22px;
  }

  @media (max-width: ${breakpoints.sm.min}px) {
    padding: 20px 3px;
    margin-top: 12px;
  }
`;

const TieDisclaimer = styled.div`
  margin: 2em 1em;
  margin-bottom: 0;
  font-size: 0.7em;
  color: ${colors.championshipBackground};
  font-family: 'Tahoma';
  letter-spacing: 1px;
  text-align: center;
  max-width: 600px;

  @media (max-width: ${breakpoints.sm.min}px) {
    margin: 1em 2em;
    font-size: 0.5em;
    line-height: 1.5em;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  top: 80px;

  @media (max-width: ${breakpoints.lg.max}px) {
    top: 25px;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    justify-content: center;
    padding: 0;
    margin-top: 70px;
  }
`;

const ButtonContainer = styled.div`
  background-color: blue;
  position: absolute;
  background: linear-gradient(to right, blue 17%, green 40%, yellow 60%, red 100%);
  padding: 5px;
  border-radius: 60px;
  cursor: pointer;
  user-select: none;
`;

const InsideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Tahoma-Bold';
  background-color: white;
  border-radius: 60px;
  width: 350px;
  padding: 10px 0;

  @media (max-width: ${breakpoints.lg.max}px) {
    width: 300px;
  }
`;

const VoteHereText = styled.div`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
`;

const ClickHere = styled.div`
  font-size: 18px;
  margin-top: 5px;
  letter-spacing: 2px;
  color: #D9B86E;
`;

const Champion = styled.div`
  font-size: 18px;
  font-family: 'MuseoSans-500';
  margin: 30px 0 -10px;
  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 16px;
  }
`;

const SmallDisclaimer = styled.div`
  font-size: 10px;
  font-family: 'MuseoSans-500';
  margin-top: 20px;
`;

export default IndexPage;
